import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { grey, green, amber } from 'material-colors';
import { Icon, Button } from '@blueprintjs/core';
import { navigate } from 'gatsby';
import ReactTooltip from "react-tooltip";

import plansConfiguration from './plans-configuration';
import basicPlanImg from '../../../static/img/illustration-plan-basic.svg';
import standardPlanImg from '../../../static/img/illustration-plan-standard.svg';
import professionalPlanImg from '../../../static/img/illustration-plan-professional.svg';
import enterprisePlanImg from '../../../static/img/illustration-plan-enterprise.svg';

const PricingColumn = ({ planCode, currency, interval }) => {

    const [plan, setPlan] = useState(null);
    const [planHeader, setPlanHeader] = useState(null);
    const [imagePlan, setImagePlan] = useState(null);
    const [priceBlock, setPriceBlock] = useState(null);
    const [actionButton, setActionButton] = useState(null);
    const { t, i18n } = useTranslation();
   
    // Select plan configuration for the planCode
    useEffect(() => {

        // Recover plan configuration
        const planConfiguration = plansConfiguration.find(planConfig => planConfig.code === planCode);

        if (planConfiguration) {

            // Set image plan
            switch (planConfiguration.code) {
                case 'basic':
                    setImagePlan(basicPlanImg);
                    break;
                case 'standard':
                    setImagePlan(standardPlanImg);
                    break;
                case 'professional':
                    setImagePlan(professionalPlanImg);
                    break;
                case 'enterprise':
                    setImagePlan(enterprisePlanImg);
                    break;
                default:
                    break;
            }

            // Set plan header
            if (planConfiguration.most_popular || planConfiguration.promo_code) {
                setPlanHeader(
                    <div style={{
                        ...planStyles.box.header,
                        backgroundColor: planConfiguration.most_popular ? green[200] : amber[200]
                    }}>
                        {t(`pricing:${ planConfiguration.most_popular ? 'most_popular' : `promo_codes.${ planConfiguration.promo_code }` }`).toUpperCase()}
                    </div>
                );
            }

            // Set action button
            if (planConfiguration.code === 'enterprise') {
                setActionButton(
                    <div style={planStyles.box.body.enterpriseBlockButton}>
                        <Button
                            text={t("pricing:contact_us").toUpperCase()}
                            intent="success"
                            style={{ boxShadow: 'none'}}
                            large={true}
                            onClick={() => i18n.language === 'en' ? navigate('/en/book-demo') : navigate('/es/reserva-demo')}
                        />
                    </div>
                );
            } else {
                setActionButton(
                    <div className='text-center mv20'>
                        <a
                            href={`https://app.biuwer.com/signup?plan=${planConfiguration.code}&language=${i18n.language || 'en'}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-success"
                            style={{ color: 'white', textDecoration: 'none', height: 40 }}
                        >
                            {t("pricing:trial.try_for_free").toUpperCase()}
                        </a>
                    </div>
                );
            }

            // Set plan
            setPlan(planConfiguration);
        }
    }, [planCode, t]);

    // Calculate the price block (includes amount and currency) when plan is available
    useEffect(() => {
        let block = null;

        // Non enterprise plans
        if (plan && plan.code !== 'enterprise') {
            
            // Prepare the plan configuration objects needed
            const monthPriceObj = plan.prices.find(price => price.currency === currency && price.interval === 'month');
            const yearPriceObj = plan.prices.find(price => price.currency === currency && price.interval === 'year');

            // Calculate prices dinamically. All plans include the minimum amount of Data Connections at no cost.
            const monthPriceEur = (monthPriceObj && monthPriceObj.amount) || '--';
            const monthPriceUsd = (monthPriceObj && monthPriceObj.amount) || '--';
            const yearPriceEur = (yearPriceObj && yearPriceObj.amount) || '--';
            const yearPriceUsd = (yearPriceObj && yearPriceObj.amount) || '--';

            block = (
                <div>
                    <div style={{ height: 78 }}>
                        <div style={planStyles.box.body.price}>
                            {interval === 'year' ? currency === 'eur' ? (
                                <div className='mr20' style={planStyles.box.body.price.annual}>
                                    <span style={{ fontSize: 24 }}>{monthPriceEur}</span>
                                    <span style={{ fontSize: 12 }}>{` €`}</span>
                                </div>
                            ) : (
                                <div className='mr20' style={planStyles.box.body.price.annual}>
                                    <span style={{ fontSize: 12 }}>{`$ `}</span>
                                    <span style={{ fontSize: 24 }}>{monthPriceUsd}</span>
                                </div>
                            ) : null}
                            <div style={planStyles.box.body.price.monthly}>
                                {currency === 'eur' ? [
                                    <span key="eurPrice" style={{ fontSize: 40 }}>{interval === 'month' ? monthPriceEur : yearPriceEur}</span>,
                                    <span key="eurSymbol" style={{ fontSize: 20 }}>{` €`}</span>
                                ] : [
                                    <span key="usdSymbol" style={{ fontSize: 20 }}>{`$ `}</span>,
                                    <span key="usdPrice" style={{ fontSize: 40 }}>{interval === 'month' ? monthPriceUsd : yearPriceUsd}</span>
                                ]}
                            </div>
                        </div>
                        <div className='text-center'>
                            <div style={planStyles.box.body.price.text}>
                                {interval === 'year' ? t(`pricing:per_month`) + ', ' + t(`pricing:billed_yearly`) : t(`pricing:per_month`)}
                            </div>
                        </div>
                    </div>
                    <div style={planStyles.box.body.separator_line}/>
                    <div style={planStyles.box.body.features_intro}>
                        {plan && t(`pricing:included.intro_plan`)}
                    </div>

                    {/* Main products included in plan */}
                    {plan && plan.products && plan.products.map((product, index) => {
                        return (
                            <div style={planStyles.box.body.product} key={product.code}>
                                <div style={planStyles.box.body.product.quantity}>
                                    <span>{product.quantity === 'unlimited' ? t('pricing:unlimited') : product.quantity}</span>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <span>{t(`pricing:products.${product.code}`)}</span>
                                    </div>
                                    {product.code === 'data_usage' && product.quantity === 'unlimited' ? (
                                    <div className="ml10">
                                        <div data-tip data-for={`unlimited_data_usage_${plan.code}`}>
                                            <Icon icon="info-sign" iconSize={16} color={green[500]} />
                                        </div>
                                        <ReactTooltip
                                            id={`unlimited_data_usage_${plan.code}`}
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            z-index="100"
                                        >
                                            <span style={{ fontSize: 11, margin: 5 }}>{t(product.label)}</span>
                                        </ReactTooltip>
                                    </div>
                                    ) : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        // Enterprise plan has a specific Price Block content
        if (plan && plan.code === 'enterprise') {
            block = (
                <div>
                    <div style={planStyles.box.body.enterprise}>
                        <div>
                            <span>{t("pricing:plans.enterprise.designed_for")}</span>
                        </div>
                    </div>
                    <div style={planStyles.box.body.separator_line}/>
                    <div style={planStyles.box.body.features_intro}>
                        {plan && t(`pricing:included.intro_plan`)}
                    </div>
                    <div>
                    {plan && plan.products && plan.products.map((product, index) => {
                    return (
                        <div className={index === 0 ? 'mt10' : 'mt0'} style={planStyles.box.body.product} key={product.code}>
                            <div style={planStyles.box.body.product.quantity}>
                                <span>{product.quantity === 'unlimited' ? t('pricing:unlimited') : product.quantity}</span>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <span>{t(`pricing:products.${product.code}`)}</span>
                                </div>
                                {product.code === 'data_usage' && product.quantity === 'unlimited' ? (
                                <div className="ml10">
                                    <div data-tip data-for={`unlimited_data_usage_${plan.code}`}>
                                        <Icon icon="info-sign" iconSize={16} color={green[500]} />
                                    </div>
                                    <ReactTooltip
                                        id={`unlimited_data_usage_${plan.code}`}
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        z-index="100"
                                    >
                                        <span style={{ fontSize: 11, margin: 5 }}>{t(product.label)}</span>
                                    </ReactTooltip>
                                </div>
                                ) : null}
                            </div>
                        </div>
                    )})}
                    </div>
                </div>
            );
        }

        setPriceBlock(block);
    }, [plan, currency, interval, i18n.language]); //eslint-disable-line

    return (
        <div className={`col-12 col-md-6 col-lg-3 mb15`}>
            {plan && !planHeader ? (
                <div style={planStyles.no_header}/>
            ) : null}
            <div style={planStyles.box}>
                {planHeader}
                <div style={planStyles.box.body}>
                    <div style={planStyles.box.body.name}>
                        <h4>{plan && t(`pricing:plans.${plan.code}.name`)}</h4>
                    </div>
                    <div style={planStyles.box.body.best_for}>
                        {plan && t(`pricing:plans.${plan.code}.best_for`)}
                    </div>
                    <div style={planStyles.box.body.image}>
                        <img src={imagePlan} alt="" style={{ width: 115 }} />
                    </div>
                    {priceBlock}
                    <div style={planStyles.box.body.separator_line}/>
                    <div style={planStyles.box.body.features_intro}>
                        {plan && t(`pricing:plans.${plan.code}.feature_intro`)}
                    </div>
                    {plan && plan.features && plan.features.map((feature, index) => {
                        return (
                            <div className={index === 0 ? 'pt10' : 'pt0'} style={planStyles.box.body.feature} key={feature.code}>
                                <div style={planStyles.box.body.feature.icon}>
                                    <Icon icon="endorsed" iconSize={16} color={green[500]} />
                                </div>
                                <div>
                                    <span>{t(`pricing:features.${feature.code}`)}</span>
                                </div>
                            </div>
                        );
                    })}
                    {actionButton}
                </div>
            </div>
        </div>
    );
};

export default PricingColumn;

// Plan styles
const headerHeight = 25;
const planStyles = {
    no_header: {
        height: headerHeight
    },
    baseQtyButton: {
        border: '1px solid',
        textAlign:'center',
        width:'24px',
        height:'24px',
        fontSize: '16px',
        fontWeight: 'bold',
        outline: 'none'
    },
    enabledQtyButton: {
        borderColor: amber[500],
        cursor: 'pointer'
    },
    disabledQtyButton: {
        borderColor: grey[400],
        cursor: 'not-allowed'
    },
    box: {
        transition: 'all 0.15s',
        backgroundColor: 'white',
        border: `1px solid ${grey[300]}`,
        borderRadius: 3,
        boxShadow: '0 0 18px 1px rgba(46,92,166, 0.08)',
        '&:hover': {
            transform: 'scale(1.015)',
            border: `1px solid ${amber[500]}`,
            boxShadow: '0 0 18px 9px rgba(46,92,166, 0.08)'
        },
        header: {
            height: headerHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 'bold'
        },
        body: {
            padding: 15,
            name: {
                textAlign: 'center'
            },
            best_for: {
                fontSize: 13,
                textAlign: 'center',
                marginBottom: 10,
                height: 60
            },
            image: {
                height: 90,
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 10
            },
            price: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline',
                annual: {
                    textDecoration: 'line-through',
                    color: grey[600],
                    height: 30
                },
                monthly: {
                    fontWeight: 'bold',
                    height: 50
                },
                text: {
                    fontSize: 16
                }
            },
            enterprise: {
                textAlign: 'center',
                fontSize: 13,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            },
            enterpriseBlockButton: {
                textAlign: 'center',
                fontSize: 13,
                marginTop: 20,
                marginBottom: 20,
            },
            features_intro: {
                textAlign: 'left',
                paddingLeft: 20,
                fontSize: 13,
                fontWeight: 'bold',
                marginBottom: 10
            },
            feature: {
                fontSize: 13,
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row',
                icon: {
                    width: 60,
                    textAlign: 'right',
                    marginRight: 10
                }
            },
            separator_line: {
                borderBottom: `solid 1px ${grey[300]}`,
                margin: '20px 0',
            },
            product: {
                height: 24,
                fontSize: 13,
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                quantity: {
                    width: 60,
                    textAlign: 'right',
                    marginRight: 10,
                    fontWeight: 'bold'
                }
            }
        }
    }
};