import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import MainLayout from '../../components/main-layout';
import HeaderSection from './header-section';
import PricingPlansHeader from './pricing-plans-header';
import PricingPlansSection from './pricing-plans-section';
import PricingPlansFooter from './pricing-plans-footer';
import GetFreeQuickStartPackage from '../Landing-Pages/Free-Quick-Start-Package/get-free-quick-start-package-section';
import PricingFaqsSection from './pricing-faqs-section';
import IncludedInEveryPlan from './included-in-every-plan-section';
import StartTrialSection from './start-trial-section';
import RequestDemoSection from '../../components/RequestDemoSection';

const Pricing = (props) => {
    const { t, i18n } = useTranslation(),
        { pageContext } = props;

    // Billing interval state
    const [interval, setInterval] = useState('month');

    // Currency state
    const [currency, setCurrency] = useState('eur');

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <HeaderSection key="pricing-section-1" {...props} t={t} />
            <PricingPlansHeader
                key="pricing-section-3"
                {...props}
                t={t}
                interval={interval}
                selectBillingInterval={setInterval}
                currency={currency}
                selectBillingCurrency={setCurrency}
            />
            <PricingPlansSection
                key="pricing-section-4"
                {...props}
                interval={interval}
                currency={currency}
            />
            <PricingPlansFooter
                key="pricing-section-5"
                t={t}
            />
            <IncludedInEveryPlan t={t} i18n={i18n} />
            <StartTrialSection t={t} i18n={i18n} />
            <GetFreeQuickStartPackage t={t} i18n={i18n} />
            <PricingFaqsSection key="pricing-faqs-section" t={t} i18n={i18n} />
            <RequestDemoSection key="request-demo-section" t={t} i18n={i18n} />
        </MainLayout>
    )
};

export default Pricing;