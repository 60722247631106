import { Link } from 'gatsby'
/** @jsx jsx */
import { jsx } from '@emotion/react';

const FirstSection = (props) => {
    const { t, i18n } = props;

    const utmString = '?utm_medium=online_campaign&utm_source=biuwer_website&utm_campaign=2020_biuwer_free_start_package&utm_content=biuwer_pricing_page&utm_term=get_free_quick_start_package';

    return (
        <section style={{ background: 'linear-gradient(#2E5CA6, #2E5CA6)', color: 'white' }} className="pv50">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>{t('services:quickStartPackage.specialOfferTitle')}</h3>
                        <h5 className="mb30">{t('services:quickStartPackage.specialOfferSubtitle')}</h5>
                        <Link
                            to={ i18n.language === 'en' ? '/en/lp/free-quick-start-package' + utmString : '/es/lp/paquete-inicio-rapido-gratuito' + utmString }
                            role="link"
                            className="btn btn-outline"
                            style={{
                                marginRight: '15px',
                                fontSize: '15px',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                letterSpacing: '0.65px',
                                fontWeight: 500,
                                color: 'white',
                                borderColor: 'white'
                            }}
                        >
                            {t('services:imInterested').toUpperCase()}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FirstSection;