import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';

import config from '../../../gatsby-config';

class HeaderSection extends React.Component  {
    render() {
        const { t } = this.props;

        return (
            <header
                id="headerSection"
                key="header"
                css={styles.mainSection}
            >
                <div className="container-fluid pt30 mb20">
                    <div className="row text-center">
                        <div className="col-12 mb20">
                            <h1 style={{ color: 'white' }}>{t('pricing:introduction')}</h1>
                            <h3 className="mv20" style={{ color: 'white' }}>{t('pricing:free_trial')}</h3>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

const styles = {
    mainSection: {
        paddingTop: config.siteMetadata.navHeaderHeight,
        background: 'linear-gradient(#2E5CA6, #2E5CA6)'
    }
};

export default HeaderSection;