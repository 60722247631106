import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'gatsby';

import FaqItem from './faq-item';
import pricingFagsImg from '../../../static/img/illustration-pricing-faqs.svg';

const PricingFaqsSection = ({ t, i18n }) => {
    return (
        <>
            <div className="container">
                <div className="row mb30 pt40">
                    <div className="col-12 text-center">
                        <h2>{t('pricing:faq_title')}</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mb30">
                    <div className="col-12 col-lg-4">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '20px 0'
                        }}>
                            <p>{t('pricing:faq_intro')}</p>
                            <p>{t('pricing:faq_intro_2')}</p>
                            <div style={{ textAlign: 'center', margin: '40px 0' }}>
                                <img src={pricingFagsImg} alt="" style={{ width: '80%' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8">
                        <FaqItem question={t('pricing:faqs.i_need_more.question')} answer={t('pricing:faqs.i_need_more.answer')} />
                        <FaqItem question={t('pricing:faqs.i_want_a_demo.question')}>
                            <p>
                                <Trans i18nKey="pricing:faqs.i_want_a_demo.answer">
                                    S0<Link to={ i18n.language === 'en' ? '/en/contact' : '/es/contacto' }>S1</Link>S2
                                </Trans>
                            </p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.unlimited_meaning.question')}>
                            <p>
                                <Trans i18nKey="pricing:faqs.unlimited_meaning.answer">
                                    S0<strong>S1</strong>S2
                                </Trans>
                            </p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.signup_howto.question')}>
                            <p>
                                <Trans i18nKey="pricing:faqs.signup_howto.answer">
                                    S0<Link to={ i18n.language === 'en' ? '/en/contact' : '/es/contacto' }>S1</Link>S2
                                </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="pricing:faqs.signup_howto.answer_2">
                                    S0<strong>S1</strong>S2
                                </Trans>
                            </p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.trial_howto.question')} answer={t('pricing:faqs.trial_howto.answer')} />
                        <FaqItem question={t('pricing:faqs.extend_trial.question')} answer={t('pricing:faqs.extend_trial.answer')} />
                        <FaqItem question={t('pricing:faqs.payment_howto.question')}>
                            <p>{t('pricing:faqs.payment_howto.answer')}</p>
                            <p>{t('pricing:faqs.payment_howto.answer_2')}</p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.annual_advantages.question')}>
                            <p>
                                <Trans i18nKey="pricing:faqs.annual_advantages.answer">
                                    S0<strong>S1</strong>S2<strong>S3</strong>S4
                                </Trans>
                            </p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.payment_methods.question')} answer={t('pricing:faqs.payment_methods.answer')} />
                        <FaqItem question={t('pricing:faqs.get_invoices.question')} answer={t('pricing:faqs.get_invoices.answer')} />
                        <FaqItem question={t('pricing:faqs.secure_payments.question')}>
                            <p>
                                <Trans i18nKey="pricing:faqs.secure_payments.answer">
                                    S0<a href="https://stripe.com/en-es" target="_blank" rel="noopener noreferrer">S1</a>S2
                                </Trans>
                            </p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.change_plan.question')} answer={t('pricing:faqs.change_plan.answer')} />
                        <FaqItem question={t('pricing:faqs.cancel_subscription.question')} answer={t('pricing:faqs.cancel_subscription.answer')} />
                        <FaqItem question={t('pricing:faqs.professional_services.question')}>
                            <p>
                                <Trans i18nKey="pricing:faqs.professional_services.answer">
                                    S0<Link to={ i18n.language === 'en' ? '/en/contact' : '/es/contacto' }>S1</Link>S2
                                </Trans>
                            </p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.need_help.question')}>
                            <p>{t('pricing:faqs.need_help.answer')}</p>
                            <ul>
                                <Trans i18nKey="pricing:faqs.need_help.answer_2">
                                    <li key="need_help_1">S0</li>
                                    <li key="need_help_2">S1</li>
                                    <li key="need_help_3">S2</li>
                                </Trans>
                            </ul>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.user_types.question')}>
                            <p>{t('pricing:faqs.user_types.answer')}</p>
                            <p>{t('pricing:faqs.user_types.answer_2')}</p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.connection_types.question')}>
                            <p>
                                <Trans i18nKey="pricing:faqs.connection_types.answer">
                                    S0<Link to={ i18n.language === 'en' ? '/en/product' : '/es/producto' }>S1</Link>S2
                                </Trans>
                            </p>
                            <p>{t('pricing:faqs.connection_types.answer_2')}</p>
                        </FaqItem>
                        <FaqItem question={t('pricing:faqs.on_premise.question')}>
                            <p>
                                <Trans i18nKey="pricing:faqs.on_premise.answer">
                                    S0<a href="mailto:sales@biuwer.com" target="_blank" rel="noopener noreferrer">S1</a>S2
                                </Trans>
                            </p>
                        </FaqItem>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PricingFaqsSection;

