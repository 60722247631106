import React from 'react';

import PricingColumn from './pricing-column';

const PricingPlansSection = ({ currency, interval }) => {

    const pricingColumnStaticProps = {
        currency,
        interval
    };

    return (
        <div className="container" >
            <div className="row mb20">
                <PricingColumn planCode="basic" {...pricingColumnStaticProps} />
                <PricingColumn planCode="standard" {...pricingColumnStaticProps} />
                <PricingColumn planCode="professional" {...pricingColumnStaticProps} />
                <PricingColumn planCode="enterprise" {...pricingColumnStaticProps} />
            </div>
        </div>
    );
};

export default PricingPlansSection;

