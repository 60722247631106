/** @jsx jsx */
import { jsx } from '@emotion/react';

const PricingPlansFooter = ({ t }) => {
    return (
        <div className="container">
            <div className="mb15 fs13" style={{ textAlign: 'right' }}>
                <p>{t(`pricing:add_ons`)}</p>
            </div>
        </div>
    );
};

export default PricingPlansFooter;