import React from 'react';
import { Icon } from "@blueprintjs/core";
import { green } from "material-colors";

const StartTrialSection = ({ t, i18n, location }) => {
    return (
        <div className="container pv20">
            <div className="row mb30">
                <div className="col-12" style={{ textAlign: 'center' }}>
                    <h3 style={{ marginBottom: 20 }}>{t('pricing:trial.start')}</h3>
                    <div style={{
                        width: 350,
                        margin: '0px auto'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 5
                        }}>
                            <div style={{ width: 70 }}>
                                <Icon icon="endorsed" iconSize={20} color={green[500]} />
                            </div>
                            <div className="bold" style={{ width: '80%', textAlign: 'left' }}>{t('pricing:trial.no_setup')}</div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 5
                        }}>
                            <div style={{ width: 70 }}>
                                <Icon icon="endorsed" iconSize={20} color={green[500]} />
                            </div>
                            <span className="bold" style={{ width: '80%', textAlign: 'left' }}>{t('pricing:trial.no_contracts')}</span>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 5
                        }}>
                            <div style={{ width: 70 }}>
                                <Icon icon="endorsed" iconSize={20} color={green[500]} />
                            </div>
                            <span className="bold" style={{ width: '80%', textAlign: 'left' }}>{t('pricing:trial.cancel_anytime')}</span>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 20
                        }}>
                            <div style={{ width: 70 }}>
                                <Icon icon="endorsed" iconSize={20} color={green[500]} />
                            </div>
                            <span className="bold" style={{ width: '80%', textAlign: 'left' }}>{t('pricing:trial.no_credit_card')}</span>
                        </div>
                    </div>
                    <a
                        href={`https://app.biuwer.com/signup?language=${i18n.language || 'en'}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-success"
                        style={{ color: 'white', textDecoration: 'none' }}
                    >
                        {t("pricing:trial.create_account").toUpperCase()}
                    </a>
                    <div style={{ marginTop: 20, marginBottom: 10 }}>
                        <span>{t('pricing:trial.prices_without_taxes')}</span>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <span>{t('pricing:trial.vat_info')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartTrialSection;