const EUR_USD_EXCHANGE_RATE = 1.2; // Euro to US Dollar exchange rate

// Base prices PER MONTH IN EUROS
const basicPlanPriceMonthEur = 50;
const standardPlanPriceMonthEur = 200;
const professionalPlanPriceMonthEur = 500;
export const advancedUserPriceMonthEur = 20;
export const connectionPriceMonthEur = 10;
export const basicUser25BucketPriceMonthEur = 50;

// Included advanced user per plan 
const advancedUsersBasicPlan = 1;
const advancedUsersStandardPlan = 3;
const advancedUsersProfessionalPlan = 5;

// Included basic user per plan 
const basicUsersBasicPlan = 5;
const basicUsersStandardPlan = 25;
const basicUsersProfessionalPlan = 100;

// Included data connections per plan 
const dataConnectionsBasicPlan = 3;
const dataConnectionsStandardPlan = 5;
const dataConnectionsProfessionalPlan = 10;

// Conversion from Monthly to Annual prices
export const advancedUserPriceYearEur = Math.ceil(advancedUserPriceMonthEur * 10 / 12);
export const connectionPriceYearEur = Math.ceil(connectionPriceMonthEur * 10 / 12);
const basicPlanPriceYearEur = Math.ceil(basicPlanPriceMonthEur * 10 / 12);
const standardPlanPriceYearEur = Math.ceil(standardPlanPriceMonthEur * 10 / 12);
const professionalPlanPriceYearEur = Math.ceil(professionalPlanPriceMonthEur * 10 / 12);

// Conversion from prices in EUR to USD
export const advancedUserPriceMonthUsd = Math.ceil(advancedUserPriceMonthEur * EUR_USD_EXCHANGE_RATE);
export const advancedUserPriceYearUsd = Math.ceil(advancedUserPriceMonthEur * 10 / 12 * EUR_USD_EXCHANGE_RATE);
export const connectionPriceMonthUsd = Math.ceil(connectionPriceMonthEur * EUR_USD_EXCHANGE_RATE);
export const connectionPriceYearUsd = Math.ceil(connectionPriceMonthEur * 10 / 12 * EUR_USD_EXCHANGE_RATE);
const basicPlanPriceMonthUsd = Math.ceil(basicPlanPriceMonthEur * EUR_USD_EXCHANGE_RATE);
const basicPlanPriceYearUsd = Math.ceil(basicPlanPriceMonthEur * 10 / 12 * EUR_USD_EXCHANGE_RATE);
const standardPlanPriceMonthUsd = Math.ceil(standardPlanPriceMonthEur * EUR_USD_EXCHANGE_RATE);
const standardPlanPriceYearUsd = Math.ceil(standardPlanPriceMonthEur * 10 / 12 * EUR_USD_EXCHANGE_RATE);
const professionalPlanPriceMonthUsd = Math.ceil(professionalPlanPriceMonthEur * EUR_USD_EXCHANGE_RATE);
const professionalPlanPriceYearUsd = Math.ceil(professionalPlanPriceMonthEur * 10 / 12 * EUR_USD_EXCHANGE_RATE);

// Plans configuration objects
const plansConfiguration = [
    {
        "code": "basic",
        "most_popular": false,
        "promo_code": null,
        "show_prices": true,
        "prices": [{
            "currency": "eur",
            "interval": "month",
            "amount": basicPlanPriceMonthEur
        }, {
            "currency": "eur",
            "interval": "year",
            "amount": basicPlanPriceYearEur
        }, {
            "currency": "usd",
            "interval": "month",
            "amount": basicPlanPriceMonthUsd 
        }, {
            "currency": "usd",
            "interval": "year",
            "amount": basicPlanPriceYearUsd
        }],
        "features": [{
            "code": "basic_connectors"
        }, {
            "code": "upload_data_file"
        }, {
            "code": "export_data_file"
        }, {
            "code": "favorites"
        }, {
            "code": "page_card_filters"
        }, {
            "code": "dependent_filters"
        }],
        "products": [{
            "code": "advanced_user",
            "quantity": advancedUsersBasicPlan
        }, {
            "code": "basic_user",
            "quantity": basicUsersBasicPlan
        }, {
            "code": "data_connection",
            "quantity": dataConnectionsBasicPlan
        }, {
            "code": "data_usage",
            "quantity": "unlimited",
            "label": "pricing:included.temporarilyFree"
        }]
    }, {
        "code": "standard",
        "most_popular": true,
        "promo_code": null,
        "show_prices": true,
        "prices": [{
            "currency": "eur",
            "interval": "month",
            "amount": standardPlanPriceMonthEur
        }, {
            "currency": "eur",
            "interval": "year",
            "amount": standardPlanPriceYearEur
        }, {
            "currency": "usd",
            "interval": "month",
            "amount": standardPlanPriceMonthUsd
        }, {
            "currency": "usd",
            "interval": "year",
            "amount": standardPlanPriceYearUsd
        }],
        "features": [{
            "code": "embedding"
        }, {
            "code": "row_access_level"
        }, {
            "code": "data_blending"
        }, {
            "code": "rbac"
        }, {
            "code": "reverse_engineering"
        }, {
            "code": "calculated_fields"
        }],
        "products": [{
            "code": "advanced_user",
            "quantity": advancedUsersStandardPlan
        }, {
            "code": "basic_user",
            "quantity": basicUsersStandardPlan
        }, {
            "code": "data_connection",
            "quantity": dataConnectionsStandardPlan
        }, {
            "code": "data_usage",
            "quantity": "unlimited",
            "label": "pricing:included.temporarilyFree"
        }]
    }, {
        "code": "professional",
        "most_popular": false,
        "promo_code": null,
        "show_prices": true,
        "prices": [{
            "currency": "eur",
            "interval": "month",
            "amount": professionalPlanPriceMonthEur
        }, {
            "currency": "eur",
            "interval": "year",
            "amount": professionalPlanPriceYearEur
        }, {
            "currency": "usd",
            "interval": "month",
            "amount": professionalPlanPriceMonthUsd
        }, {
            "currency": "usd",
            "interval": "year",
            "amount": professionalPlanPriceYearUsd
        }],
        "features": [{
            "code": "professional_connectors"
        }, {
            "code": "api_access"
        }, {
            "code": "custom_styles"
        }, {
            "code": "multi_language"
        }, {
            "code": "data_portals"
        }, {
            "code": "smart_data_alerts"
        }],
        "products":[{
            "code": "advanced_user",
            "quantity": advancedUsersProfessionalPlan
        }, {
            "code": "basic_user",
            "quantity": basicUsersProfessionalPlan
        }, {
            "code": "data_connection",
            "quantity": dataConnectionsProfessionalPlan
        }, {
            "code": "data_usage",
            "quantity": "unlimited",
            "label": "pricing:included.temporarilyFree"
        }]
    }, {
        "code": "enterprise",
        "most_popular": false,
        "promo_code": null,
        "show_prices": false,
        "prices": [],
        "features": [{
            "code": "custom_connectors"
        }, {
            "code": "custom_onboarding"
        }, {
            "code": "white_labeling"
        }, {
            "code": "dedicated_server"
        }, {
            "code": "priority_support"
        }, {
            "code": "sso"
        }],
        "products": [{
            "code": "advanced_user",
            "quantity": "unlimited"
        }, {
            "code": "basic_user",
            "quantity": "unlimited"
        }, {
            "code": "data_connection",
            "quantity": "unlimited"
        }, {
            "code": "data_usage",
            "quantity": "unlimited",
            "label": "pricing:included.temporarilyFree"
        }]
    }
];

export default plansConfiguration;