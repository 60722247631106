import { grey, amber } from 'material-colors';
/** @jsx jsx */
import { jsx } from '@emotion/react';

const PricingPlansHeader = ({ t, interval, selectBillingInterval, currency, selectBillingCurrency }) => {

    const styles = {
        button: {
            display: 'inline-flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            padding: '6px 12px',
            cursor: 'pointer',
            border: '1px solid',
            borderRadius: 2,
            transition: 'all 0.4s',
            backgroundColor: 'transparent',
            fontSize: '13px',
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: grey[100]
            },
            '&:focus': {
                outline: 'none'
            }
        }
    };

    return (
        <div className="container">
            <div className="mb15 fs13" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{ flex: '1 1 auto' }}>
                    <div style={{ marginBottom: 10, minHeight: 25, display: 'flex' }}>
                        <div style={{ lineHeight: '2em', marginRight: 20 }}>
                            <span>{t('pricing:choose_billing_period')}</span>
                        </div>
                        {interval === 'year' ? (
                            <span className="badge bg-success" style={{ padding: 5, fontSize: 13, maxHeight: 24 }}>{t('pricing:annual_reward')}</span>
                        ) : null}
                    </div>
                    <div>
                        <span
                            css={styles.button}
                            style={{
                                borderColor: interval === 'month' ? amber[500] : 'transparent'
                            }}
                            className="mr10"
                            onClick={ () => selectBillingInterval('month')}
                            onKeyDown={ () => selectBillingInterval('month')}
                            role="button"
                            tabIndex={0}
                        >
                            {t('pricing:monthly')}
                        </span>
                        <span
                            css={styles.button}
                            style={{
                                borderColor: interval === 'year' ? amber[500] : 'transparent'
                            }}
                            onClick={() => selectBillingInterval('year')}
                            onKeyDown={() => selectBillingInterval('year')}
                            role="button"
                            tabIndex={0}
                        >
                            {t('pricing:annual')}
                        </span>
                        
                    </div>
                </div>
                <div style={{ textAlign: 'right', flex: '1 1 auto', minWidth: 150 }}>
                    <div style={{ marginBottom: 10, minHeight: 25, lineHeight: '2em' }}>
                        <span>{t('pricing:choose_currency')}</span>
                    </div>
                    <div>
                    <span
                            css={styles.button}
                            style={{
                                borderColor: currency === 'eur' ? amber[500] : 'transparent'
                            }}
                            className="mr10"
                            onClick={() => selectBillingCurrency('eur')}
                            onKeyDown={() => selectBillingCurrency('eur')}
                            role="button"
                            tabIndex={0}
                        >
                            {'EUR'}
                        </span>
                        <span
                            css={styles.button}
                            style={{
                                borderColor: currency === 'usd' ? amber[500] : 'transparent'
                            }}
                            onClick={() => selectBillingCurrency('usd')}
                            onKeyDown={() => selectBillingCurrency('usd')}
                            role="button"
                            tabIndex={0}
                        >
                            {'USD'}
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricingPlansHeader;

