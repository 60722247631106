import React from 'react';

const IncludedInEveryPlanSection = ({ t, i18n }) => {
    return (
        <div className="container pv20">
            <div className="row mb30 text-center">
                <h2>{t('pricing:included.title')}</h2>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-3 mb40">
                    <h4>{t('pricing:included.content')}</h4>
                    <p>{t('pricing:included.contentMsg')}</p>
                </div>
                <div className="col-md-12 col-lg-3 mb40">
                    <h4>{t('pricing:included.cards')}</h4>
                    <p>{t('pricing:included.cardsMsg')}</p>
                </div>                
                <div className="col-md-12 col-lg-3 mb40">
                    <h4>{t('pricing:included.dataObjects')}</h4>
                    <p>{t('pricing:included.dataObjectsMsg')}</p>
                </div>
                <div className="col-md-12 col-lg-3 mb40">
                    <h4>{t('pricing:included.dataUsage')}</h4>
                    <p>{t('pricing:included.dataUsageMsg')}</p>
                </div>
            </div>
        </div>
    );
};

export default IncludedInEveryPlanSection;